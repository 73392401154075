<template>
    <div>
        <CancellationDialog @reject="closeDialog($event)"
                            @dismiss="dismiss"
                            :modal="modall"></CancellationDialog>
        <div class="d-flex justify-space-between align-center">
            <p style="font-size: 18px;"
               class="font-weight-semibold text-black">
                {{ $t('Bookings') }}
            </p>
        </div>
        <v-divider></v-divider>
        <v-card id="user-list"
                class="mt-6">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-green">
                        {{ $t('Bookings Listing') }}
                    </span>
                    <!-- </div> -->
                </div>
                <div class="d-flex align-center pb-5">
                    <v-select v-model="selectStatus"
                              single-line
                              dense
                              outlined
                              :items="statuses"
                              variant="underlined"
                              item-text="name"
                              item-value="id"
                              @change="onChangeFilter()"
                              hide-details
                              prepend-inner-icon="mdi-sort-variant"
                              :placeholder="$t('Select Status')"
                              class="user-list-search me-3"></v-select>

                    <v-text-field single-line
                                  dense
                                  outlined
                                  v-model="searchText"
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  @input="onSearch()"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>


            <div class="d-flex mb-8 mx-6">
                <div style="cursor:pointer;"
                     @click="activeBooking"
                     class="mr-6 d-flex flex-column justify-center">
                    <div class="text-blue font-weight-semibold">
                        <span class="d-flex">
                            <div v-bind:class="{ 'boat-info': active, 'past': !active }">
                                {{ $t('Active Bookings') }}
                            </div>
                        </span>
                    </div>
                </div>
                <div class="d-flex"
                     style="height: 30px;">
                    <v-divider class="my-2"
                               :vertical="true"></v-divider>
                </div>
                <div style="cursor:pointer;"
                     @click="pastBooking"
                     class="ml-6 d-flex flex-column justify-center">
                    <div class="text-blue font-weight-semibold">
                        <span class="d-flex">
                            <div class="pa-2"
                                 v-bind:class="{ 'boat-info': past, 'past': !past }">
                                {{ $t('Past Bookings') }}
                            </div>
                        </span>
                    </div>
                </div>
            </div>


            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :options.sync="options"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">
                    <div class="font-size text-black"> {{ item.id }}</div>
                </template>
                <template #[`item.userName`]="{ item }">
                    <div class="font-size text-black"> {{ item.userName }}</div>
                </template>


                <template #[`item.boat_name`]="{ item }">
                    <div class="font-size text-black"> {{ item.boat_name }}</div>
                </template>
                <template #[`item.owner_name`]="{ item }">
                    <div class="font-size text-black"> {{ item.owner_name }}</div>
                </template>

                <template #[`item.status`]="{ item }">
                    <!-- <div class="font-size text-black"> {{ item.status }}</div> -->
                    <v-chip :color="resolveStatusVariant(item.status).variant"
                            :class="`v-chip-light-bg ${resolveStatusVariant(item.status).variant}--text font-weight-thin text-caption`">
                        {{ $t(resolveStatusVariant(item.status).name) }}
                    </v-chip>
                </template>

                <template #[`item.packages`]="{ item }">
                    <div class="font-size text-black"> {{ item.packages }}</div>
                </template>
                <template #[`item.name`]="{ item }">
                    <div class="font-size text-black"> {{ item.name }}</div>
                </template>

                <template #[`item.created_at`]="{ item }">
                    <div class="font-size text-black"> {{ item.created_at }}</div>
                </template>

                <!-- <template #[`item.payment_method`]="{ item }">
                    <div class="font-size text-black"> {{ item.payment_method }}</div>
                </template> -->



                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="text-green">{{ item.actions }}</div>
                    <div class="d-flex align-center justify-center">

                        <!-- <div v-if="role != 'boat_owner'"> -->
                            <v-tooltip bottom
                                       v-if="item.status == 'hold'">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           v-bind="attrs"
                                           class="text-green"
                                           @click="updateBoatStatus('accept', item)"
                                           v-on="on">
                                        <v-icon size="18"
                                                color="#00B2A9"
                                                class="text-green">
                                            {{ icons.mdiCheck }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Accept') }}</span>
                            </v-tooltip>
                        <!-- </div> -->

                        <!-- reject  -->
                        <!-- <div v-if="role != 'boat_owner'"> -->
                            <v-tooltip bottom
                                       v-if="item.status == 'hold'">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           color="#FF0000"
                                           v-bind="attrs"
                                           @click="rejectBoat(item)"
                                           v-on="on">
                                        <v-icon size="18"
                                                color="warn">
                                            {{ icons.mdiClose }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Reject') }}</span>
                            </v-tooltip>
                        <!-- </div> -->

                        <v-tooltip bottom
                                   v-if="item.status == 'confirmed'">
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       class="text-green"
                                       @click="updateBoatStatus('in_progress', item)"
                                       v-on="on">
                                    <v-icon size="18"
                                            color="#00B2A9"
                                            class="text-green">
                                        {{ icons.mdiProgressCheck }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Check In') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom
                                   v-if="item.status == 'in_progress'">
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       class="text-green"
                                       @click="updateBoatStatus('completed', item)"
                                       v-on="on">
                                    <v-icon size="18"
                                            color="#00B2A9"
                                            class="text-green">
                                        {{ icons.mdiCheckAll }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Check Out') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       @click="viewBooking(item)"
                                       v-on="on">
                                    <v-icon color="#00B2A9"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import bookingServices from '@/services/booking-services';
import { mdiCheck, mdiCheckAll, mdiClose, mdiEyeOutline, mdiProgressCheck } from '@mdi/js';
// composition function
import router from '@/router';
import userServices from '@/services/user-services';
import CancellationDialog from '@/views/dialogs/CancellationDialog.vue';
import useUsersList from './useBookingList';
import store from '@/store';
import darkMode from '@core/utils/modalColor';

export default {
    components: {
        CancellationDialog
    },
    data: () => ({
        applicationList: '',
        active: true,
        shouldApplyClass: true,
        admin: false,
        past: false,
        role: 'admin',
        dialog: false,
        rowsPerPageOptions: [10],
        selectedItem: null,
        modall: false,
        statuses: [
            {
                "id": '',
                "name": 'All'
            },
            {
                "id": 'hold',
                "name": 'Pending'
            },
            {
                "id": 'confirmed',
                "name": 'Confirmed'
            },
            {
                "id": 'cancelled',
                "name": 'Cancelled'
            },
            {
                "id": 'completed',
                "name": 'Completed'
            },
            {
                "id": 'in-progress',
                "name": 'InProgress'
            }
        ]
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
            }
        })
    },

    mounted()
    {
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }

        let currentRole = JSON.parse(localStorage.getItem('userData'));
        this.role = currentRole.role;

        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData.role == 'admin')
        {
            this.admin = true;
        }
    },

    methods: {
        viewBooking(item)
        {
            router.push(`/booking/${item.id}`);
        },
        // getAllOwners()
        // {
        //     let data = {
        //         role: 'boat_owner'
        //     }
        //     userServices.getOwnerList(data).then(resp =>
        //     {
        //         if (resp.statusCode == 200)
        //         {
        //             this.boatOwners = resp.data.users
        //         }
        //     })
        // },
        activeBooking()
        {
            this.active = true;
            this.past = false;
            this.checkBooking = 'active_bookings'
        },
        pastBooking()
        {
            this.past = true;
            this.active = false;
            this.checkBooking = 'past_bookings '
        },
        changeStatus()
        {

        },
        dismiss()
        {
            this.modall = false;
        },
        closeDialog(event)
        {
            this.modall = false;
            this.updateBoatStatus('reject', this.selectedItem, event);
        },
        rejectBoat(item)
        {
            console.log(item)
            this.modall = true;
            this.selectedItem = item;
        },

       


        updateBoatStatus(status, item, event)
        {
            let dict = {
                id: item.id,
                status: status,
            };
            let url = 'boat_bookings/' + status;

            if (status == 'in_progress')
            {
                url = 'boat_bookings/in-progress';
                dict['booking_notes'] = "no booking_notes";
            }
            else if (status == 'completed')
            {
                url = 'boat_bookings/completed';
                dict['booking_notes'] = "no booking_notes";
            }
            else if (status == 'reject')
            {
                url = 'boat_bookings/reject'
                dict['rejection_notes'] = event;
                dict.status = 'cancelled'
            }
            else if (status == 'accept')
            {
                url = 'boat_bookings/accept';
                dict.status = 'confirmed';
            }

            bookingServices.updateApplicationStatus(dict, url).then(resp =>
            {
                this.options = [];
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    this.updateStatus = 'statusChange'
                    this.$swal({
                        title: `Status Updated`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        // refresh here
                    })
                }
            });
        },
    },

    setup()
    {
        const {
            userListTable,
            selectStatus,
            searchText,
            updateStatus,
            rejectionStatus,
            checkBooking,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveStatusVariant,
            resolveRole,
            onChangeFilter,
            onSearch
        } = useUsersList()

        return {
            tableColumns,
            selectStatus,
            searchText,
            updateStatus,
            rejectionStatus,
            checkBooking,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveStatusVariant,
            resolveRole,
            onChangeFilter,
            onSearch,
            icons: {
                mdiEyeOutline, mdiCheck, mdiClose, mdiProgressCheck, mdiCheckAll
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

.past {
    background-color: #e9e9e9 !important;
    // border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: #00B2A9;
    font-size: 14px;
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}
</style>
