import { ref, watch } from '@vue/composition-api';

import bookingServices from '@/services/booking-services';

import store from '@/store';
import { useUtils } from '@core/libs/i18n';
import debounce from "lodash/debounce";
import moment from 'moment';

const { t } = useUtils()

export default function useUsersList()
{

    const userListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            align: 'start',
            value: 'id',
            sortable: false
        },
        { text: t('User Name'), value: 'userName', sortable: false, align: 'center' },
        { text: t('Boat Name'), value: 'boat_name', sortable: false, align: 'center' },
        { text: t('Owner Name'), value: 'owner_name', sortable: false, align: 'center' },
        { text: t('Status'), value: 'status', sortable: false, align: 'center' },
        { text: t('Packages'), value: 'packages', sortable: false, align: 'center' },
        { text: t('City'), value: 'name', sortable: false, align: 'center' },
        { text: t('Created'), value: 'created_at', sortable: false, align: 'center' },
        // { text: 'Payment Method', value: 'payment_method', sortable: false, align: 'center' },
        {
            text: t('Actions'),
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const selectStatus = ref('')
    const searchText = ref('')
    const updateStatus = ref('')
    const rejectionStatus = ref('')



    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const selectedTableData = ref([])
    const checkBooking = ref([])


    const checkStatus = status =>
    {
        if (status === 'in_progress') return true;
        // if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
        // if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
        // if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
        // if (status === 'completed') return { variant: 'success', name: 'Completed' }
    }

    const resolveStatusVariant = status =>
    {
        if (status === 'hold') return { variant: 'success', name: 'Pending' }
        if (status === 'pending') return { variant: 'success', name: 'Pending' }
        if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
        if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
        if (status === 'in_progress') return { variant: 'warning', name: 'Checked In' }
        if (status === 'completed') return { variant: 'success', name: 'Completed' }
        // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

        return { variant: 'secondary', name: 'Not Available' }
    }

    const fetchUsers = () =>
    {
        store.set('accounts/loader', true);
        let status = '';
        let selectId = '';
        let searchValue = '';
        let bookingStatus = 'active_bookings'

        if (rejectionStatus.value == 'statusChange')
        {
            options.value.itemsPerPage = 10;
            options.value.page = 1;
        }
        if (updateStatus.value == 'statusChange')
        {
            options.value.itemsPerPage = 10;
            options.value.page = 1;
        }
        if (selectStatus.value)
        {
            status = selectStatus.value
        }
        if (checkBooking.value.length > 0)
        {
            bookingStatus = checkBooking.value;
        }

        if (searchQuery.value)
        {
            selectId = searchQuery.value;
        }
        if (searchText.value)
        {
            searchValue = searchText.value;
        }
        bookingServices.getBookingList(options, status, bookingStatus, selectId, searchValue).then(resp =>
        {

            // const { filteredData, total } = response.data
            if (resp.status == 200)
            {
                store.set('accounts/loader', false);

                let data = resp.data.data.bookings;
                data.forEach(element =>
                {
                    element.created_at = moment(new Date(element.created_at)).format('DD/MM/yyyy')
                    if (element?.user)
                    {
                        element.userName = element.user.first_name + ' ' + element.user.last_name;
                    }
                    if (element?.boat)
                    {
                        element.boat_name = element.boat.boat_name;

                        // if (element.boat.boat_packages)
                        // {
                        //     element.packages = element.boat.boat_packages.length > 0 ? 'Yes' : 'No'
                        // }
                    }
                    if (element.boats_owner)
                        {
                            element.owner_name = element.boats_owner.owner_name
                        }
                    // if (element.packages_ids)
                    //     {
                            element.packages = element.packages_ids > 0 ? 'Yes' : 'No'
                        // }
                    if (element?.city)
                    {
                        element.name = element.city.name;
                    }

                });
                totalUserListTable.value = resp.data.data.pagination.count
                userListTable.value = data;
                loading.value = false
            }

        })

    }

    const onSearch = debounce(() =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchUsers();
    }, 500);

    const onChangeFilter = () =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchUsers();
    };

    watch([updateStatus, rejectionStatus, checkBooking, options], () =>
    {
        // start loading

        loading.value = true
        selectedTableData.value = []
        fetchUsers()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        selectStatus,
        searchText,
        updateStatus,
        rejectionStatus,
        options,
        userListTable,
        checkBooking,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchUsers,
        resolveRole,
        resolveStatusVariant,
        checkStatus,
        onChangeFilter,
        onSearch
    }
}
